<template>
<docs-layout>
  <div  class="text-left space-y-4">
    <p>We use <pre class="contents">asom-icon</pre> for ours spinner icon with tailwind.css for animation</p>
    <asom-icon icon="spinner" class="animate-spin" />
  </div>
</docs-layout>
</template>
<script>
import DocsLayout from '@/docs/DocsLayout'

export default {
  components: {
    DocsLayout,
  },
  data() {
    return {}
  }
}
</script>